<template>
  <div style="height: inherit">
    <b-col lg="12 px-0 mx-0" v-if="progress != 0">
      <b-card no-body class="card-employee-task">
        <b-card-header>
          <b-card-title>{{
            $t("Pages.MasterFile.Title.downloading_wait")
          }}</b-card-title>
        </b-card-header>
        <div class="mx-2 pb-1">
          <b-progress
            animated
            :value="progress"
            variant="success"
            class="progress-bar-success"
          />
        </div>
      </b-card>
    </b-col>

    <b-row class="no-gutters">
      <b-col lg="3" md="12" class="mx-0">
        <div class="sidebar-detached sidebar-left">
          <div class="sidebar">
            <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
              <!-- sidebar header -->

              <h6 class="filter-heading d-none d-lg-block w-50">
                {{ $t("Pages.MasterFile.Button.Filters") }}
              </h6>

              <!-- by price -->
              <b-card class="w-90">
                <div class="product-categories">
                  <h6 class="filter-title mt-1">
                    {{ $t("Pages.MasterFile.Button.filter_by_price") }}
                  </h6>
                  <b-form-radio-group
                    v-model="filters.pricing"
                    class="categories-radio-group mt-2"
                    stacked
                    :options="filterOptions.pricing"
                    value-field="item"
                    text-field="name"
                    @change="getNewData"
                  />
                </div>
              </b-card>

              <!-- Categories -->
              <b-card class="w-90">
                <div class="product-categories">
                  <h6 class="filter-title mt-1">
                    {{ $t("Pages.MasterFile.Button.filter_by_cat") }}
                  </h6>
                  <b-form-radio-group
                    v-model="filters.categories"
                    class="categories-radio-group mt-2"
                    stacked
                    :options="filterOptions.categories"
                    value-field="item"
                    text-field="name"
                    @change="getNewData"
                  />
                </div>
              </b-card>
            </div>
          </div>

          <div
            class="body-content-overlay"
            :class="{ show: mqShallShowLeftSidebar }"
          />
        </div>
      </b-col>
      <b-col lg="9"  md="12" class="mx-0">
        <!-- Overlay -->
        <div class="body-content-overlay" />

        <!-- Searchbar -->
        <div class="ecommerce-searchbar mt-1 ml-lg-1">
          <b-row class="d-none d-lg-block">
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input
                  @keyup="getNewData"
                  v-model="filters.q"
                  :placeholder="$t('Base.Placeholder.search_product')"
                  class="search-product"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="d-lg-none">
            <b-col cols="12">
              <h6 class="filter-heading d-none d-lg-block">
                {{ $t("Pages.MasterFile.Button.Filters") }}
              </h6>
            </b-col>
          </b-row>

          <!-- Filters' Card -->
          <b-card class="d-lg-none">
            <div class="product-categories">
              <h6 class="filter-title mt-1">
                {{ $t("Pages.MasterFile.Button.filter_by_price") }}
              </h6>
              <b-form-radio-group v-model="filters.pricing" class="categories-radio-group mt-2" stacked
                :options="filterOptions.pricing" value-field="item" text-field="name" @change="getNewData" />
            </div>
          </b-card>

          <!-- Categories -->
          <b-card class="d-lg-none">
            <div class="product-categories">
              <h6 class="filter-title mt-1">
                {{ $t("Pages.MasterFile.Button.filter_by_cat") }}
              </h6>
              <b-form-radio-group v-model="filters.categories" class="categories-radio-group mt-2" stacked
                :options="filterOptions.categories" value-field="item" text-field="name" @change="getNewData" />
            </div>
          </b-card>

          <b-row class="d-lg-none">
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input
                  @keyup="getNewData"
                  v-model="filters.q"
                  :placeholder="$t('Base.Placeholder.search_product')"
                  class="search-product"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>

        <!-- Prodcuts -->
        <section class="grid-view ml-lg-1" v-if="products.length > 0">
          <b-card
            v-for="product in products"
            :key="product.id"
            class="ecommerce-card"
            no-body
          >
            <template
              v-if="product.type == 'music'"
              style="direction: ltr"
              dir="ltr"
            >
              <h5
                class="bg-gray mb-0 px-1 py-1 my-auto text-justify"
                style="padding-top: 2px"
              >
                {{ product.title.slice(0, 200) }}
              </h5>
              <vue-plyr>
                <audio controls crossorigin>
                  <source :src="product.preview" type="audio/mp3" />
                </audio>
              </vue-plyr>
              <div class="item-options d-flex py-1 mt-0 justify-content-end">
                <!-- <feather-icon icon="EyeIcon" @click="openPageFile(product)" class="mx-1"  size="25" /> -->
                <feather-icon
                  icon="InfoIcon"
                  @click="handleCartActionClick(product)"
                  size="25"
                />
                <feather-icon
                  icon="DownloadIcon"
                  @click="downloadMusic(product)"
                  class="mx-1"
                  size="25"
                />
              </div>
            </template>
            <template v-else>
              <div class="item-img text-center p-0">
                <b-img
                  :alt="`${product.id}`"
                  fluid
                  class="card-img-top"
                  :src="product.preview"
                  @click="openPageFile(product)"
                />
              </div>
              <h5
                class="bg-gray mb-0 px-1 py-1 my-auto text-justify"
                style="padding-top: 2px"
              >
                {{ product.title.slice(0, 200) }}
              </h5>
              <div class="item-options d-flex py-1 mt-0 justify-content-end">
                <div class="item-meta mx-1">
                  <h3
                    class="text-primary mb-0 card-text"
                    v-if="product.count_coin != 0"
                  >
                    {{ product.count_coin }}<span class="">*</span>
                    <b-img
                      class=""
                      :src="
                        require('@/assets/images/icons/mastercoin_icon.png')
                      "
                      width="25"
                    />
                  </h3>
                  <p class="card-text text-primary mb-0" v-else>
                    {{ $t("Pages.MasterFile.Title.Free") }}
                  </p>
                </div>
                <feather-icon
                  icon="EyeIcon"
                  @click="openPageFile(product)"
                  size="25"
                  class="custom_pointer"
                />
                <feather-icon
                  icon="DownloadIcon"
                  @click="handleCartActionClick(product)"
                  class="mx-1 custom_pointer"
                  size="25"
                />
              </div>
            </template>
          </b-card>
        </section>
        <!-- end of products/// -->

        <section class="mt-2 ml-lg-1" v-else>
          <b-card class="ecommerce-card" no-body>
            <template>
              <b-alert
          
                show
                dismissible
                fade
                class="mb-0"
                variant="warning"
              >
                <div class="alert-body">
                  <span>{{ $t("Pages.MasterFile.Title.not_found") }}</span>
                </div>
              </b-alert>
            </template>
          </b-card>
        </section>
        <section class="ml-lg-1">
          <b-row>
            <b-col cols="12" v-if="pages > 1">
              <b-pagination-nav
                :link-gen="linkGen"
                :number-of-pages="pages"
                use-router
                class="mb-0"
              />
            </b-col>
          </b-row>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BModal,
  BButton,
  BPaginationNav,
  BInputGroupPrepend,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuePlyr from "vue-plyr";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPaginationNav,
    BInputGroupPrepend,
    VuePlyr,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardSubTitle,
    BCardTitle,
    BModal,
    BProgress,
    BAlert,
  },
  data() {
    return {
      mqShallShowLeftSidebar: false,
      filters: {
        q: "",
        categories: 1,
        pricing: null,
      },
      filterOptions: {
        categories: [],
        pricing: [
          {
            name: this.$t("Pages.MasterFile.Title.All"),
            item: null,
          },
          {
            name: this.$t("Pages.MasterFile.Title.Free"),
            item: 0,
          },
          {
            name: this.$t("Pages.MasterFile.Title.Money"),
            item: 1,
          },
        ],
      },
      pages: 1,
      products: [],
      progress: 0,
      NotificationStartDownlaod: false,
      count_coin: 0,
    };
  },
  mounted() {
    if (this.$route.meta.use_history) {
      this.filters.categories = localStorage.getItem("master_file_type")
        ? localStorage.getItem("master_file_type")
        : 1;
      this.filters.q = localStorage.getItem("master_file_search")
        ? localStorage.getItem("master_file_search")
        : "";
      this.filters.pricing = localStorage.getItem("master_file_pricing")
        ? localStorage.getItem("master_file_pricing")
        : null;
      this.$route.query.page = localStorage.getItem("master_file_pages")
        ? localStorage.getItem("master_file_pages")
        : 1;
      this.pages = localStorage.getItem("master_file_pages")
        ? localStorage.getItem("master_file_pages")
        : 1;
    } else {
      localStorage.removeItem("master_file_type");
      localStorage.removeItem("master_file_search");
      localStorage.removeItem("master_file_pricing");
      localStorage.removeItem("master_file_pages");
    }
    this.getCountCoin();
    this.getNewData();
    this.getProductCategories();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getNewData();
      }
    },
  },
  methods: {
    getNewData() {
      localStorage.setItem("master_file_type", this.filters.categories);
      localStorage.setItem("master_file_search", this.filters.q);
      localStorage.setItem("master_file_pricing", this.filters.pricing);
      localStorage.setItem(
        "master_file_pages",
        this.$route.query.page ? this.$route.query.page : 1
      );
      this.$http
        .get(
          `/api/master-file/items/list?type=${this.filters.categories}&name=${
            this.filters.q
          }&pricing=${this.filters.pricing}&page=${
            this.$route.query.page ? this.$route.query.page : 1
          }`
        )
        .then((response) => {
          this.products = response.data.data;
          this.pages = response.data.last_page;
        });
    },
    showModal() {
      this.$swal({
        title: this.$t("Base.Alert.Error"),
        text: this.$t("Pages.MasterFile.Title.upgrade_account"),
        icon: "warning",
        confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: "MasterCoin" }).catch(() => {});
        }
      });
    },
    getCountCoin() {
      this.$http.get(`/api/dashboard/getCountCoin`).then((response) => {
        this.count_coin = response.data.count_coin;
      });
    },
    handleCartActionClick(product) {
      this.$http.get("/api/user/setting/check-verify").then((response) => {
        if (response.data && response.data.status) {
          if (this.progress == 0) {
            if (this.count_coin > product.count_coin) {
              this.$http
                .post(`/api/master-file/item/download`, { id: product.id })
                .then((response) => {
                  if (response.status == 200) {
                    this.$http({
                      url: response.data,
                      method: "GET",
                      responseType: "blob",
                      onDownloadProgress: (progressEvent) => {
                        if (
                          this.progress == 0 &&
                          !this.NotificationStartDownlaod
                        ) {
                          window.scrollTo(0, 0);
                          this.makeToast(
                            this.$t("Base.Alert.Successful"),
                            this.$t("Base.Alert.downloading_wait"),
                            "success",
                            10000
                          );
                          this.NotificationStartDownlaod = true;
                        }
                        this.progress = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        ); // you can use this to show user percentage of file downloaded
                      },
                    }).then((res) => {
                      if (res.status == 200) {
                        if (res.data) {
                          var fileURL = window.URL.createObjectURL(
                            new Blob([res.data])
                          );
                          var fURL = document.createElement("a");
                          fURL.href = fileURL;
                          fURL.setAttribute("download", product.name_file);
                          document.body.appendChild(fURL);
                          fURL.click();
                          this.makeToast(
                            this.$t("Base.Alert.Successful"),
                            this.$t("Base.Alert.download_success"),
                            "success"
                          );
                          this.progress = 0;
                          this.NotificationStartDownlaod = false;
                          this.$root.$emit("updateCoin", true);
                        }
                      } else {
                        this.progress = 0;
                        this.NotificationStartDownlaod = false;
                        this.makeToast(
                          this.$t("Base.Alert.Error"),
                          this.$t(
                            "Base.Alert.verify_email_phone"
                          ),
                          "danger"
                        );
                      }
                    });
                  }
                });
            } else {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.not_enough_coin"),
                "danger"
              );
              this.showModal();
            }
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.wait_until_download"),
              "danger"
            );
          }
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.verify_email_phone"),
            "danger"
          );
        }
      });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getProductCategories() {
      this.$http.get("/api/master-file/items/categories").then((response) => {
        let category = [];
        response.data.map((e) => {
          category.push({
            item: e.value,
            name: this.$t(`Pages.MasterFile.Title.${e.text}`),
          });
        });
        this.filterOptions.categories = category;
      });
    },
    downloadMusic(product) {
      this.$http.get("/api/user/setting/check-verify").then((response) => {
        if (response.data && response.data.status) {
          this.$http({
            url: product.preview,
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              if (this.progress == 0 && !this.NotificationStartDownlaod) {
                window.scrollTo(0, 0);
                this.makeToast(
                  this.$t("Base.Alert.Successful"),
                  this.$t("Base.Alert.downloading_wait"),
                  "success",
                  10000
                );
                this.NotificationStartDownlaod = true;
              }
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              ); // you can use this to show user percentage of file downloaded
            },
          }).then((res) => {
            if (res.status == 200) {
              if (res.data) {
                var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                var fURL = document.createElement("a");
                fURL.href = fileURL;
                fURL.setAttribute("download", product.name_preview);
                document.body.appendChild(fURL);
                fURL.click();
                this.makeToast(
                  this.$t("Base.Alert.Successful"),
                  this.$t("Base.Alert.download_success"),
                  "success"
                );
                this.progress = 0;
                this.NotificationStartDownlaod = false;
                this.$root.$emit("updateCoin", true);
              }
            } else {
              this.progress = 0;
              this.NotificationStartDownlaod = false;
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.verify_email_phone"),
                "danger"
              );
            }
          });
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.verify_email_phone"),
            "danger"
          );
        }
      });
    },
    openPageFile(file) {
      this.$router
        .push({ name: "masterFileInfo", query: { id: file.id } })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";
@import "~vue-plyr/dist/vue-plyr.css";
</style>
<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.item-img a {
  width: 100% !important;
}

.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.pos {
  top: 0px !important;
  right: 0px !important;
  left: 0;
}
</style>

<style lang="scss">
.width_fit_content {
  width: fit-content !important;
}
.w-90 {
  width: 90%;
}
.pl-6 {
  padding-right: 0px;
  padding-left: 100px;
  padding-bottom: 3px;
  padding-top: 3px;
}

[dir="rtl"] .plyr {
  direction: ltr !important;
}

[dir] .plyr--audio .plyr__controls {
  background: none !important;
  background: none !important;
}
.custom_pointer {
  cursor: pointer;
}
</style>
